<template>
  <!-- 光纤传感器 -->
  <div>
    <div v-for="(product, index) in products" :key="index">
      <div v-if="product.id === productId" class="product-details">
        <div class="product-top">
          <h2>{{ product.name }}</h2>
          <p class="product-description">{{ product.description }}</p>
        </div>
        <div class="carousel-container">
          <img :src="product.carouselImage" style="width: 400px;"/>
        </div>
      </div>
      <div v-if="product.id === productId" class="parameter-vbom">
        <div class="parameter-title">
          <h3>产品参数</h3>
          <div class="separator"></div>
        </div>
        <div v-if="product.id === productId" class="image-gallery">
          <img :src="product.galleryImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productsList from "@/libs/productsList.js"; // 假设这是您的产品信息列表

export default {
props: ["productId"],
  data() {
    return {
      products: [
        {
          id: "tc_10001_0001",
          name: "热脉冲系统",
          description: "主动分布式温度测量系统提供了沿光缆所有位置的热传导信息。 这种测量能力对于在自然热梯度有限的环境下监测流体的存在和流动，或在原位表征热特性，都是至关重要的。\n Silixa的热脉冲系统（HPS）允许通过提供时间和功率控制的脉冲来加热光纤电缆。它通过一个电源和带有嵌入式加热元件的光纤电缆进行操作。HPS可以与任何Silixa分布式温度传感器相结合，通过共享接口控制两个装置，提供一个完整的监测解决方案。可设定加热循环的总测量时间、频率和强度，以满足安装的测量要求。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/HeatPulseSystem.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p1.png")
        },
        {
          id: "tc_10001_0002",
          name: "XT-DTS™",
          description: "Silixa的坚固分布式温度传感器XTDTS™是目前市场上用于远程和恶劣环境中性能非常高的DTS。设计用于恶劣环境下远程操作系统的XT-DTS具有良好的精度和可靠性，具有一流的操作温度范围和可使用太阳能或风能供电的低功耗。甚至在以前无法到达的地方也可以通过无线或卫星链路对XT-DTS进行远程配置和控制，从而能够进行远程数据收集，并能够进行有效的资产优化和环境风险管理。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/XT-DTS.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p2.png")
        },
        {
          id: "tc_10001_0003",
          name: "ULTIMA™ DTS",
          description: "ULTIMA DTS系列是分布式温度传感器系列，现升级为无风扇、低功耗及超大的内部存储空间。ULTIMA提供了温度分辨率及采样分辨率，即0.01°C 、25cm,以及广泛的工作温度范围。 ULTIMA为独立系统、包含板载PC及内置用户友好的操作界面，它提供4或8通道选择。此设备对以下范围内的空间和温度分辨率进行了优化型号M：2/5/10Km，型号L：10/20/35Km。另外此系统可配置为单端测量或双端测量，最小测量时间为1秒。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/ULTIMADTS.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p3.png")
        },
        {
          id: "tc_10001_0004",
          name: "Carina传感系统",
          description: "Silixa的坚固分布式温度传感器XTDTS™是目前市场上用于远程和恶劣环境中性能非常高的DTS。设计用于恶劣环境下远程操作系统的XT-DTS具有良好的精度和可靠性，具有一流的操作温度范围和可使用太阳能或风能供电的低功耗。甚至在以前无法到达的地方也可以通过无线或卫星链路对XT-DTS进行远程配置和控制，从而能够进行远程数据收集，并能够进行有效的资产优化和环境风险管理。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/pc4.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/pg4.png")
        },
        {
          id: "tc_10001_0005",
          name: "iDAS™ 智能分布式声学传感器",
          description: "Silixa的坚固分布式温度传感器XTDTS™是目前市场上用于远程和恶劣环境中性能非常高的DTS。设计用于恶劣环境下远程操作系统的XT-DTS具有良好的精度和可靠性，具有一流的操作温度范围和可使用太阳能或风能供电的低功耗。甚至在以前无法到达的地方也可以通过无线或卫星链路对XT-DTS进行远程配置和控制，从而能够进行远程数据收集，并能够进行有效的资产优化和环境风险管理。",
          carouselImage: require("@/assets/images/ESci/AcousticSensor/iDAS.png"),
          galleryImage: require("@/assets/images/ESci/AcousticSensor/p5.png")
        },
        // 这里添加更多产品
      ]
    };
  }
};
</script>
<style lang="less" scoped>
@import "../../../assets/styles/GeoSciProduct.less";
</style>
<style >

</style>
